<template>
    <section class="h-100">
        <div class="row mx-0 text-general px-3 border-bottom align-items-center" style="height:49px;">
            <template v-if="[1].indexOf(pedido.estado) > -1">
                <div class="btn-action border mx-2 cr-pointer" type="button" @click="imprimir">
                    <i class="icon-printer f-20" />
                </div>
                <p><b>ID. {{ pedido.id }} </b>Pedido sin confirmar</p>
                <div v-if="$can('botones_abastecimiento_confirmar_pedido')" class="btn-outline px-3 br-8 ml-auto mx-2" type="button" @click="rechazarPedido">
                    Rechazar
                </div>
                <div v-if="$can('botones_abastecimiento_confirmar_pedido')" type="button" class="btn-general px-3 br-8 bg-general text-white" @click="confirmarPedido">
                    Confirmar
                </div>
            </template>
            <template v-else-if="[2,201,202,3,31].indexOf(pedido.estado) > -1">
                <div class="col-6 d-middle justify-content-between pl-0">
                    <div class="d-middle">
                        <div class="btn-action border mr-2 cr-pointer" @click="imprimir">
                            <i class="icon-printer f-20" />
                        </div>
                        <p v-show="pedido.estado === 2"><b>ID. {{ pedido.id }} </b>Pedido Confirmado</p>
                        <p v-show="pedido.estado === 201"><b>ID. {{ pedido.id }} </b>Pedido en alistamiento</p>
                        <p v-show="pedido.estado === 202"><b>ID. {{ pedido.id }} </b>Pedido en empaque</p>
                        <p v-show="pedido.estado === 3"><b>ID. {{ pedido.id }} </b>Pedido Enviado</p>
                        <p v-show="pedido.estado === 31"><b>ID. {{ pedido.id }} </b>Pedido en Reclamo</p>
                    </div>
                    <template v-if="[2,201,202].indexOf(pedido.estado) > -1 && pedido.id_pedido == null && $can('botones_abastecimiento_gestionar_productos')">
                        <el-tooltip class="item" effect="light" content="Añadir Productos" placement="bottom">
                            <div class="btn-red br-8 d-middle-center text-white" @click="$refs.modalProductos.toggle()">
                                <i class="icon-plus f-18 cr-pointer text-white" />
                            </div>
                        </el-tooltip>
                    </template>
                    <div v-if="pedido.id_pedido">
                        <el-tooltip v-show="[2,201,202].indexOf(pedido.estado) > -1 && pedido.datos_pedido.estado != 11 && $can('botones_abastecimiento_gestionar_productos')" class="item" effect="light" content="Añadir Productos" placement="bottom">
                            <div class="btn-red br-8 d-middle-center text-white" @click="$refs.modalProductos.toggle()">
                                <i class="icon-plus f-20 cr-pointer text-white" />
                            </div>
                        </el-tooltip>
                    </div>
                </div>
                <div v-if="pedido.id_pedido == null" class="col-6 justify-content-around d-middle border-left">
                    <el-tooltip v-show="pedido.estado === 3" placement="bottom" content="Historial de devolucion de productos" effect="light">
                        <i class="icon-history text-general f-23 cr-pointer" @click="historialDevueltos" />
                    </el-tooltip>
                    <el-tooltip placement="bottom" content="Historial de productos eliminados" effect="light">
                        <i class="icon-delete-clock-outline text-general f-23 cr-pointer" @click="historialEliminados" />
                    </el-tooltip>
                    <el-tooltip class="item" effect="light" :content="`Hablar con el ${$config.vendedor }`" placement="bottom">
                        <el-badge :value="pedido.cant_mensajes" :hidden="pedido.cant_mensajes<=0" class="item mx-2 mr-4">
                            <i class="icon-message-text cr-pointer f-20 text-general hover-icons" @click="verChat" />
                        </el-badge>
                    </el-tooltip>
                    <div v-show="pedido.estado === 31" type="button" class="br-10 bg-general text-white px-2 py-1">
                        Reclamo Activo
                    </div>
                    <div v-show="pedido.estado === 2 && $can('botones_abastecimiento_alistar_pedido')" type="button" class="br-10 bg-general text-white px-2 py-1" @click="$refs.modalAlistarPedido.toggle()">
                        Alistar Pedido
                    </div>
                    <div v-show="pedido.estado === 201 && $can('botones_abastecimiento_alistar_pedido')" type="button" class="br-10 bg-general text-white px-2 py-1" @click="$refs.modalEmpacarPedido.toggle()">
                        Empacar Pedido
                    </div>
                    <div v-show="pedido.estado === 202 && $can('botones_abastecimiento_enviar_pedido')" type="button" class="br-10 bg-general text-white px-2 py-1" @click="verificar_prodructos_para_envio">
                        Enviar Pedido
                    </div>
                    <div v-show="pedido.estado === 3 && $can('botones_abastecimiento_finalizar_pedido')" type="button" class="br-10 bg-general text-white px-2 py-1" @click="FinalizarPedido">
                        Finalizar Pedido
                    </div>
                    <el-dropdown class="ml-3" trigger="click" @command="accionBoton">
                        <div class="btn-action px-1 shadow border">
                            <i class="icon-dots-vertical f-20" />
                        </div>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item v-show="[2,201,202,3].indexOf(pedido.estado) > -1 && $can('botones_abastecimiento_editar_datos_pedido')" :command="1">
                                <i class="icon-calendar-clock f-20" />
                                <span>Editar Datos Pedido</span>
                            </el-dropdown-item>
                            <el-dropdown-item v-if="$can('botones_abastecimiento_cancelar_pedido')" :command="0">
                                <i class="icon-cancel-circled-outline f-20" />
                                <span>Cancelar Pedido</span>
                            </el-dropdown-item>
                            <el-dropdown-item v-show="pedido.estado == 3 && $can('botones_abastecimiento_devolver_productos')" :command="2">
                                <i class="icon-devolver f-20" />
                                <span>Devolver Productos</span>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
                <div v-else class="col px-0 justify-content-end d-middle border-left">
                    <p v-show="pedido.datos_pedido.estado == 11" class="f-14 ml-2">Esperando Confirmacion del {{ $config.cliente }}</p>
                    <div class="d-middle ml-auto">
                        <el-tooltip class="item" effect="light" :content="`Pedido correspondiente a un ${$config.cliente}` " placement="bottom">
                            <i class="icon-cliente f-20 text-general mx-2" />
                        </el-tooltip>
                        <el-tooltip v-show="pedido.estado === 3" placement="bottom" content="Historial de devolucion de productos" effect="light">
                            <div class="btn-action cr-pointer border" @click="historialDevueltos">
                                <i class="icon-history f-18" />
                            </div>
                        </el-tooltip>
                        <el-tooltip placement="bottom" content="Historial de productos eliminados" effect="light">
                            <div class="btn-action border cr-pointer mx-2" @click="historialEliminados">
                                <i class="icon-delete-clock-outline f-20 cr-pointer" />
                            </div>
                        </el-tooltip>
                        <el-tooltip class="item" effect="light" :content="`Hablar con el ${$config.vendedor}`" placement="bottom">
                            <el-badge
                            :value="pedido.cant_mensajes"
                            :hidden="pedido.cant_mensajes<=0"
                            class="btn-action border cr-pointer item mx-2"
                            >
                                <i class="icon-message-text  f-17" @click="verChat" />
                            </el-badge>
                        </el-tooltip>
                        <div v-show="pedido.estado === 31" type="button" class="btn-general px-2 f-14">
                            Reclamo Activo
                        </div>
                        <div v-show="pedido.datos_pedido.estado != 110 && pedido.datos_pedido.estado != 11 ">
                            <div v-show="pedido.estado === 2 && $can('botones_abastecimiento_alistar_pedido')" class="btn-general bg-general text-white px-3 br-8" @click="$refs.modalAlistarPedido.toggle()">
                                Alistar
                            </div>
                            <div v-show="pedido.estado === 201 && $can('botones_abastecimiento_alistar_pedido')" class="btn-general bg-general br-8 text-white px-3" @click="$refs.modalEmpacarPedido.toggle()">
                                Empacar
                            </div>
                            <div v-show="pedido.estado === 202 && $can('botones_abastecimiento_enviar_pedido')" class="btn-general bg-general text-white px-3 br-8" @click="verificar_prodructos_para_envio">
                                Enviar
                            </div>
                            <div v-show="pedido.estado === 3 && $can('botones_abastecimiento_finalizar_pedido')" class="btn-general bg-general text-white px-3 br-8" @click="FinalizarPedido">
                                Finalizar
                            </div>
                        </div>
                        <div v-show="pedido.datos_pedido.estado == 110 && $can('botones_abastecimiento_gestionar_productos')">
                            <div type="button" class="br-10 bg-general text-white p-2" @click="$refs.modalFinCambios.toggle()">
                                Fin Cambios
                            </div>
                        </div>
                        <el-dropdown class="ml-3" trigger="click" @command="accionBoton">
                            <div class="btn-action px-1 shadow border">
                                <i class="icon-dots-vertical f-20" />
                            </div>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item v-show="[2,201,202,3].indexOf(pedido.estado) > -1 && $can('botones_abastecimiento_editar_datos_pedido')" :command="1">
                                    <i class="icon-calendar-clock f-20" />
                                    <span>Editar Datos Pedido</span>
                                </el-dropdown-item>
                                <el-dropdown-item v-if="$can('botones_abastecimiento_cancelar_pedido')" :command="0">
                                    <i class="icon-cancel-circled-outline f-20" />
                                    <span>Cancelar Pedido</span>
                                </el-dropdown-item>
                                <el-dropdown-item v-show="pedido.estado == 3 && $can('botones_abastecimiento_devolver_productos')" :command="2">
                                    <i class="icon-devolver f-20" />
                                    <span>Devolver Productos</span>
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                </div>
            </template>
        </div>
        <div class="row mx-0" style="height:calc(100% - 94px);">
            <div class="col-6 px-0 border-right h-100">
                <div v-show="id_pedido !== null" class="d-flex px-3 border-bottom align-items-center" style="height:49px;">
                    <el-input v-model="buscar" size="medium" clearable placeholder="Buscar producto" class="br-20" />
                </div>
                <div ref="cont1" class="p-3 f-15 overflow-auto custom-scroll" style="height:calc(100% - 6px);">
                    <div class="row mx-0 justify-center">
                        <card-producto
                        v-for="(prod, idx) in productos.filter(data => !buscar || data.producto.detalle_producto.toLowerCase().includes(buscar.toLowerCase()))" :key="`prod-${idx}`"
                        :info="prod"
                        class="cr-pointer card-prod"
                        @accion="modalProducto"
                        @icono="cambiar_icono"
                        />
                    </div>

                    <contentLoader v-show="loading" />
                    <div v-show="id_pedido === null" class="row h-100 mx-0 align-items-center justify-content-center">
                        <div class="text-center f-15 f-600 d-middle-center flex-column" style="width:225px;">
                            <div class="d-middle-center bg-whitesmoke br-5" style="width:115px;height:115px;">
                                <img height="80" :src="funImagenGeneral()" alt="" />
                            </div>
                            <p>Selecciona uno de los pedidos activos del panel de la izquierda.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-6 px-0 h-100">
                <div class="row mx-0 border-bottom pt-2" style="height:49px;">
                    <div
                    class="col pt-0 d-middle-center cr-pointer"
                    :class="tab=='detalle'? 'border-b-black text-general':''"
                    @click="tab='detalle'"
                    >
                        Detalle
                    </div>
                    <div
                    v-if="!_.isEmpty(pedido) && (pedido.pago_estado != 1 || pedido.transferencia == 1)"
                    class="col pt-0 d-middle-center cr-pointer"
                    :class="tab=='pago'? 'border-b-black text-general':''"
                    @click="tab='pago'"
                    >
                        Pago
                    </div>
                    <div
                    v-show="pedido.estado === 31"
                    class="col pt-0 d-middle-center cr-pointer"
                    :class="tab=='reclamo'? 'border-b-black text-general':''"
                    @click="tab='reclamo'"
                    >
                        Reclamo
                    </div>
                    <div
                    v-show="pedido.novedad === 1"
                    class="col pt-0 d-middle-center cr-pointer"
                    :class="tab=='novedad'? 'border-b-black text-general':''"
                    @click="tab='novedad'"
                    >
                        Novedad
                    </div>
                </div>
                <div :class="`${tab === 'detalle' && id_pedido ? 'px-0 h-100 overflow-auto custom-scroll col' :''}`">
                    <detalle v-show="tab === 'detalle' && id_pedido" ref="detallePedido" :pedido="pedido" />
                    <pago v-show="tab === 'pago'" :pedido="pedido" admin :vista="4" />
                    <chat-reclamo v-if="tab === 'reclamo' && id_pedido" />
                    <chat-novedad v-if="tab === 'novedad' && id_pedido" @update="tab='detalle'" />
                </div>
            </div>
        </div>

        <modalDetalleProd ref="modalDetalleProd" />

        <modalRechazarPedido ref="modalRechazarPedido" @accion-rechazar="accion_rechazar" />
        <modalRechazarPedidoDirecto ref="modalRechazarPedidoDirecto" @accion-rechazar="accion_rechazar" />
        <modalCancelarPedido ref="modalCancelarPedido" @accion-cancelar="accion_cancelar" />
        <modalCancelarPedidoDirecto ref="modalCancelarPedidoDirecto" @accion-cancelar="accion_cancelar" />
        <modalChat ref="modalChat" :pedido="pedido" :leechero="cliente" @actualizarIcono="actualizarIconoChat" />

        <modalEditarPedido ref="modalEditarPedido" :editar="editar" :confirmacion="confirmar_pedido" />

        <modalFinalizarPedido ref="modalFinalizarPedido" @accion-finalizar="accion_finalizar" @credito="modalCredito(true,2)" />
        <modalStockBajo ref="modalStockBajo" />

        <modalEditarProd ref="modalEditarProd" @eliminar="modalEliminar" @update="recalcular_pedido" />

        <modalAgregarProductos ref="modalProductos" @update="update_lista" @update-pedido="update_estado_pedido" />
        <modalProductoDesconocido ref="modalProductoDesconocido" @update="recalcular_pedido" />

        <modal-devolver-prod ref="modalDevolverProd" :pedido="pedido" @update="recalcular_pedido" />

        <modal-enviar-pedido ref="modalEnviarPed" @accion-enviar="accion_enviar" />
        <modal-eliminar-producto ref="modalEliminarProducto" @update="recalcular_pedido" />

        <modal-historial-productos ref="modalHistorialProductos" />


        <modalEditarProductoNew ref="modalEditarProductoNew" @update="recalcular_pedido" @eliminar="modalEliminar" />


        <modal-historial-productos ref="modalHistorialProductos" />


        <modalEditarProductoNew ref="modalEditarProductoNew" @update="recalcular_pedido" @eliminar="modalEliminar" />


        <modal-historial-productos ref="modalHistorialProductos" />


        <modalEditarProductoNew ref="modalEditarProductoNew" @update="recalcular_pedido" @eliminar="modalEliminar" />
        <modalProductosProvedores ref="modalProductosProvedores" :pedido="pedido" @enviar="$refs.modalEnviarPed.toggle()" />


        <modalConfirmar
        ref="modalFinCambios"
        titulo="Finalizar Cambios"
        mensaje="¿Desea enviar los cambios del pedido al cliente?" icon="grocery"
        @guardar="finalizar_edicion_pedido_directo()"
        />

        <modalConfirmar
        ref="modalAlistarPedido"
        titulo="Alistar Pedido"
        mensaje="¿Desea empezar a alistar el pedido?" icon="Alistar"
        adicional="Alistar"
        no-aceptar
        @adicional="confirmar_cambio_estado(201)"
        />

        <modalConfirmar
        ref="modalEmpacarPedido"
        titulo="Empacar Pedido"
        mensaje="¿Desea pasar el pedido a empacado?" icon="Fill Outline-1"
        @guardar="confirmar_cambio_estado(202)"
        />

        <modalSiNo
        ref="modalOfrecerCredito"
        titulo="Otorgar Crédito"
        :mensaje="`Desea otorgar crédito al ${$config.vendedor }`"
        icon="money"
        @accion="modalCredito"
        />
        <modal-imprimir ref="imprimirRecibo" />
    </section>
</template>

<script>
import {mapGetters} from 'vuex'
import Pedidos from '~/services/pedidos/pedidos-admin'
import PdfPedidos from '~/services/pdf/pdf'
export default {
    components: {
        detalle: () => import('./detalle'),
        pago: () => import('~/pages/almacen/tendero/historial/pago.vue'),
        chatReclamo: () => import('./chatReclamo'),
        chatNovedad: () => import('./components/chatNovedad'),
        modalAgregarProductos: () => import('./partials/modalAgregarProductos'),
        modalDetalleProd: () => import('./partials/modalDetalleProducto'),
        modalRechazarPedido: () => import('./partials/modalRechazarPedido'),
        modalRechazarPedidoDirecto: () => import('./partials/modalRechazarPedidoDirecto'),
        modalCancelarPedido: () => import('./partials/modalCancelarPedido'),
        modalCancelarPedidoDirecto: () => import('./partials/modalCancelarPedidoDirecto'),
        modalEditarPedido: () => import('./partials/modalEditarPedido'),
        modalEditarProd: () => import('./partials/modalEditarProd'),
        modalDevolverProd: () => import('./partials/modalDevolverProducto'),
        modalEnviarPedido: () => import('./partials/modalEnviarPedido'),
        modalEliminarProducto: () => import('./partials/modalEliminarProducto'),
        modalHistorialProductos: () => import('./partials/modalHistorialProductos'),

        modalProductoDesconocido: () => import('./partials/modalProductoDesconocido'),
        modalEditarProductoNew: () => import('./partials/modalEditarProductoNew'),
        modalFinalizarPedido: () => import('./partials/modalFinalizarPedido'),

        modalStockBajo: () => import('./partials/modalStockBajo'),
        cardProducto: () => import('./partials/card-producto-pedido2'),
        modalProductosProvedores: () => import('./partials/modalProductosProvedores'),
    },
    data(){
        return {
            tab:'detalle',
            editar: false,
            confirmar_pedido:false,
            loading:false,
            cargando:false,
            buscar:'',
        }
    },
    computed:{
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
            id_pedido: 'pedidos/pedidos_admin/id_pedido',
            productos: 'pedidos/pedidos_admin/productos',
            cliente: 'pedidos/pedidos_admin/cliente',
            pedido: 'pedidos/pedidos_admin/pedido',
            sin_confirmar: 'pedidos/pedidos_admin/sin_confirmar',
            confirmados: 'pedidos/pedidos_admin/confirmados',
            enviados: 'pedidos/pedidos_admin/enviados',
            alistados: 'pedidos/pedidos_admin/alistados',
            empacados: 'pedidos/pedidos_admin/empacados',
        }),
        rol(){
            return this.$usuario.rol_nombre
        },
    },
    watch:{
        id_pedido(val){
            // console.log('entro aca',val);
            this.tab = 'detalle'
            if(val){
                this.listar_productos()
                this.buscar = ''
            }else{
                this.$store.commit('pedidos/pedidos_admin/set_id_pedido', null)
                this.$store.commit('pedidos/pedidos_admin/set_pedido', {})
                this.$store.commit('pedidos/pedidos_admin/set_productos', [])
            }
        }
    },
    // mounted () {
    //     this.recalcular_pedido();
    // },
    destroyed(){
        this.$store.commit('pedidos/pedidos_admin/set_id_pedido', null)
        this.$store.commit('pedidos/pedidos_admin/set_pedido', {})
        this.$store.commit('pedidos/pedidos_admin/set_productos', [])
        this.$store.commit('pedidos/pedidos_admin/setBanderaMetodosPagos', false)
    },
    methods: {
        async listar_productos(){
            try {
                this.loading = true
                //this.$store.commit('pedidos/pedidos_admin/set_metodos_pago', [])
                await this.$store.dispatch('pedidos/pedidos_admin/ver_pedido', Number(this.id_pedido))
                //await this.$store.dispatch('pedidos/pedidos_admin/pedidos_productos',this.id_pedido)
                //this.$store.dispatch('pedidos/pedidos_admin/pedidos_resumenes', this.id_pedido)
                // console.log('aca');
                if(this.pedido.pago_estado === 2){
                    this.$store.dispatch('pedidos/pedidos_admin/pedido_credito', this.id_pedido)
                }
            } catch (e){
                this.error_catch(e)
            } finally{
                this.loading = false
            }
        },

        devolverProducto(){
            this.$refs.modalDevolverProd.toggle(this.productos,this.id_pedido)
        },
        confirmarPedido(){
            this.confirmar_pedido = true
            this.editar = false
            this.$refs.modalEditarPedido.toggle()
        },
        rechazarPedido(){

            if(this.pedido.id_pedido != null){
                this.$refs.modalRechazarPedidoDirecto.toggle()
                return
            }

            this.$refs.modalRechazarPedido.toggle()
        },
        cancelarPedido(){
            if(this.pedido.id_pedido != null){
                this.$refs.modalCancelarPedidoDirecto.toggle()
                return
            }
            this.$refs.modalCancelarPedido.toggle()
        },
        verChat(){
            this.pedido.cant_mensajes = 0
            let soloVisual = !!this.pedido.fecha_finalizado_chat
            this.$refs.modalChat.toggle(this.id_pedido, 5, soloVisual, 1 ) // 5 = tipo pedido admin
        },
        modalEliminar(id_pedido_producto){
            this.$refs.modalEliminarProducto.toggle(id_pedido_producto)
        },
        modal_enviar_cambios(){
            this.$refs.modalEnviarCambios.toggle()
        },

        recalcular_pedido(){
            this.$store.dispatch('pedidos/pedidos_admin/listar_pedidos',this.id_cedis)
            this.$store.dispatch('pedidos/pedidos_admin/pedidos_productos', Number(this.id_pedido))
            this.$store.dispatch('pedidos/pedidos_admin/pedidos_resumenes', Number(this.id_pedido))
        },
        update_estado_pedido(){
            if(this.pedido.id_pedido){ // Aplica para cuando es pedido de un cliente
                this.$store.dispatch('pedidos/pedidos_admin/pedidos_productos', Number(this.id_pedido))
            }
        },
        FinalizarPedido(){
            this.$refs.modalFinalizarPedido.toggle()
        },
        async confirmar_cambio_estado(estado){
            try {
                if(this.id_pedido === null){
                    this.notificacion('Alerta','Por favor seleccione un pedido','warning')
                    return
                }
                const {data} = await Pedidos.confirmar_cambio_estado(this.id_pedido,estado)
                switch (estado){
                case 201:
                    this.$refs.modalAlistarPedido.toggle()
                    break;
                case 202:
                    this.$refs.modalEmpacarPedido.toggle()
                    break;
                case 3:
                    this.$refs.modalEnviarPedido.toggle()
                    if(this.pedido.pago_estado == 1){
                        this.$refs.modalOfrecerCredito.toggle()
                    }
                }

                this.recalcular_pedido()
                this.notificacion('Éxito','El Pedido ha cambiado de estado','success')

            } catch (e){
                this.error_catch(e)
            }
        },
        async verificar_prodructos_para_envio(){
            try {
                if(this.id_pedido === null){
                    this.notificacion('Alerta','Por favor seleccione un pedido','warning')
                    return
                }
                if(this.productos.length === 0){
                    this.notificacion('Alerta','Debe agregar almenos un (1) producto','warning')
                    return
                }
                const {data} = await Pedidos.verificar_productos(this.id_pedido)

                if(data.agotados > 0){
                    this.$store.commit('pedidos/pedidos_admin/set_productos_stock_bajo', data.productos)
                    this.$refs.modalStockBajo.toggle()
                    return
                }

                if(data.inventario > 0){
                    this.$store.commit('pedidos/pedidos_admin/set_productos_provedores', data.productos_provedores)
                    this.$refs.modalProductosProvedores.toggle()
                    return
                }


                this.$refs.modalEnviarPed.toggle();

            } catch (e){
                this.error_catch(e)
            }
        },
        async nueva_direccion(val){
            try {
                const {data} = await Pedidos.nueva_direccion(this.id_pedido,val)
            } catch (error){
                this.error_catch(e)
            }
        },
        modalCredito(res,opcion=1){
            if(opcion === 1){
                this.$refs.modalOfrecerCredito.toggle()
                if(res){
                    this.$refs.detallePedido.otorgarCredito()
                }
            }else if(opcion === 2){
                this.$refs.detallePedido.otorgarCredito()
            }
        },
        async editar_pedido(){
            this.confirmar_pedido = false
            this.editar = true
            this.$refs.modalEditarPedido.toggle()
        },
        async cambiar_icono(obj){
            try {
                const {data} = await Pedidos.cambiar_icono(obj)
            } catch (e){
                this.error_catch(e)
            }
        },
        modalProducto(prod){

            if(!this.$can('botones_abastecimiento_gestionar_productos')){
                this.$refs.modalDetalleProd.datos_cambio(prod,this.pedido.idm_moneda)
                return
            }

            if(this.pedido.id_pedido){
                if(this.pedido.datos_pedido.estado === 11){ // Pedniente de aprobacion del cliente
                    this.$refs.modalDetalleProd.datos_cambio(prod,this.pedido.idm_moneda)
                    return
                }
            }

            if(this.pedido.estado === 2 || this.pedido.estado === 201 || this.pedido.estado === 202){

                if(prod.desconocido === 0){
                    this.$refs.modalEditarProductoNew.datos_basicos(prod) // modal para cambia, agregar, eliminar producto
                }
                else if(prod.desconocido === 1){
                    this.$refs.modalProductoDesconocido.toggle(prod)  // modal para cambiar un producto desconocido por uno real
                }

            }else{
                if(prod.desconocido === 1){
                    this.$refs.modalProductoDesconocido.detalle_desconocido(prod)  // modal detalle desconocido
                    return
                }
                this.$refs.modalDetalleProd.datos_cambio(prod,this.pedido.idm_moneda) // modal detalle producto
            }
        },
        update_lista(){
            this.$refs.cont1.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        },
        accion_rechazar(){
            this.$store.commit('pedidos/pedidos_admin/rechazar_pedido',this.id_pedido)
            this.$store.commit('pedidos/pedidos_admin/set_id_pedido', null)
            if(this.$route.params.id_pedido != null){
                this.$route.params.id_pedido = null
                this.$router.push({name:'admin.almacen.pedidos'})
            }
        },
        accion_cancelar(){
            this.$store.dispatch('pedidos/pedidos_admin/listar_pedidos',this.id_cedis)
            this.$store.commit('pedidos/pedidos_admin/set_id_pedido', null)
            if(this.$route.params.id_pedido != null){
                this.$route.params.id_pedido = null
                this.$router.push({name:'admin.almacen.pedidos'})
            }
        },
        accion_finalizar(){
            this.$store.commit('pedidos/pedidos_admin/finalizar_pedido',this.id_pedido)
            this.$route.params.id_pedido = null
            this.$router.push({name:`admin.almacen.pedidos`})
        },
        accion_enviar(){
            if(this.pedido.pago_estado == 1 && this.$can('botones_abastecimiento_otorgar_credito')){
                this.$refs.modalOfrecerCredito.toggle()
            }
            this.recalcular_pedido()
        },

        actualizarIconoChat(){
            this.$store.commit('pedidos/pedidos/set_pedido', {...this.pedido, cant_mensajes: 0})
            // para actualizar el listado de pedidos
            let idx_confirmado = this.confirmados.findIndex(e => e.id === this.id_pedido)
            let idx_enviado= this.enviados.findIndex(e => e.id === this.id_pedido)
            let idx_sin_confirmar= this.sin_confirmar.findIndex(e => e.id === this.id_pedido)
            let idx_alistados= this.alistados.findIndex(e => e.id === this.id_pedido)
            let idx_empacados= this.empacados.findIndex(e => e.id === this.id_pedido)
            if(idx_confirmado >= 0){
                let found = this.confirmados[idx_confirmado]
                found.cant_mensajes = 0
            }else if(idx_enviado >= 0){
                let found = this.enviados[idx_enviado]
                found.cant_mensajes = 0
            }else if(idx_sin_confirmar >= 0){
                let found = this.sin_confirmar[idx_sin_confirmar]
                found.cant_mensajes = 0
            }else if(idx_alistados >= 0){
                let found = this.alistados[idx_alistados]
                found.cant_mensajes = 0
            }else if(idx_empacados >= 0){
                let found = this.empacados[idx_empacados]
                found.cant_mensajes = 0
            }
        },
        async imprimir(){
            try {
                if (this.pedido.id_pedido == null){
                    const {data} = await PdfPedidos.generar_pdf_ticket_admin_pedidos({id_pedido: this.id_pedido})
                    const url = window.URL.createObjectURL(new Blob([data], {type:"application/pdf"}));
                    const pdfWindow = window.open(url);
                    pdfWindow.print();
                    return
                }
                this.$refs.imprimirRecibo.toggle(this.id_pedido, this.pedido.id_pedido);

            } catch (e){
                this.error_catch(e)
            }
        },
        async finalizar_edicion_pedido_directo(){
            try {
                const {data} = await Pedidos.finalizar_edicion_pedido_directo(this.id_pedido)
                this.recalcular_pedido()
                this.notificacion('Éxito','Los cambios fueron enviados al cliente. Esperando confirmación...','success')
                this.$refs.modalFinCambios.toggle()
            } catch (e){
                this.error_catch(e)
            }
        },
        accionBoton(key){
            switch (key){
            case 1:
                this.editar_pedido()
                break;
            case 2:
                this.devolverProducto()
                break;
            case 0:
                this.cancelarPedido()
                break;

            default:
                break;
            }
        },
        historialDevueltos(){
            this.$refs.modalHistorialProductos.toggle(1)
        },
        historialEliminados(){
            this.$refs.modalHistorialProductos.toggle(2)
        },
    }
}
</script>

<style lang="scss" scoped>
.bg-rosa{
    background: #f5f5f5;
}
.text-orange{
    color: #FF612B;
}
.text-morado{
    color: #9508EB;
}
.alto{
    height: calc(100vh - 64px);
}
.bg-gr-prod{
    background: linear-gradient( to right, #03D6BC20, #0D4DFF20)
}
.trans-card:first-child{
    // background-color: #0d4dff20;
    animation-duration: 4s;
    animation-iteration-count: 1;
    animation-name: slidein;
}
.br-12{
    border-radius: 12px !important;
}

</style>
