import { render, staticRenderFns } from "./listado-productos.vue?vue&type=template&id=56648500&scoped=true&"
import script from "./listado-productos.vue?vue&type=script&lang=js&"
export * from "./listado-productos.vue?vue&type=script&lang=js&"
import style0 from "./listado-productos.vue?vue&type=style&index=0&id=56648500&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56648500",
  null
  
)

export default component.exports